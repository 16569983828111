@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes bottomNavigationButton {
  from {
    top: -50px;
  }
  from {
    top: 25px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "iranYekan"; /*a name to be used later*/
  src: url("./fonts/IRANYekanRegularMsn.ttf") format("truetype"); /*URL to font*/
}

@font-face {
  font-family: "iranSans"; /*a name to be used later*/
  src: url("./fonts/IRANSans(FaNum)_Medium.ttf") format("truetype"); /*URL to font*/
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "iranSansBold"; /*a name tbe used later*/
  src: url("./fonts/IRANSans(FaNum)_Bold.ttf") format("truetype"); /*URL to font*/
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "YekanBakh";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/YekanBakh-Fat.ttf") format("truetype");
  src: url("./fonts/YekanBakh-Fat.ttf") format("truetype");
}

@font-face {
  font-family: "YekanBakh";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/YekanBakh-Heavy.ttf") format("truetype");
  src: url("./fonts/YekanBakh-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "YekanBakh";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/YekanBakh-Bold.ttf") format("truetype");
  src: url("./fonts/YekanBakh-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "YekanBakh";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/YekanBakh-Medium.ttf") format("truetype");
  src: url("./fonts/YekanBakh-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "YekanBakh";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/YekanBakh-Regular.ttf") format("truetype");
  src: url("./fonts/YekanBakh-Regular.ttf") format("truetype");
}

body {
  font-family: "Roboto", sans-serif !important;
  min-height: 100vh;
  text-transform: lowercase !important;
  background-color: #f5f5f5;
}

.solution-layout {
  background-attachment: fixed;
  background-size: 100% 400px;
  @apply w-full  min-h-[6.25rem];
}

.solution-layout-container {
  @apply relative z-20 flex items-center justify-center w-full mx-auto mb-10 lg:w-9/12 xl:w-11/12;
}

.solution-body {
  @apply w-full px-5 mx-auto bg-white py-7 md:p-10 rounded-3xl;
}

.clothe-recognition-wrapper {
  @apply w-full rounded-xl min-h-[31.25rem];
}

.solution-backdrop {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  @apply w-full bg-primary  top-0 z-0 h-[15.625rem]  fixed;
}

.size-measurement-wrapper {
  /*    @apply bg-white w-full lg:w-11/12 xl:w-8/12 2xl:w-4/12 px-5 py-7 mb-10  md:p-10  rounded-3xl  min-h-[31.25rem]*/
  @apply w-full md:p-10  rounded-xl  min-h-[31.25rem];
}

.bottom-icon {
  width: 24px;
  height: 24px;
}

.bottom-icon span {
  font-size: 12px;
}

.solution-card {
  @apply flex bg-primary overflow-hidden hover:text-white min-h-[210px] lg:min-h-[15.625rem]   relative justify-start text-white flex-wrap w-full  mx-auto  px-4 pt-[3.125rem] rounded-3xl;
}

.solution-card:hover {
}
.solution-card:after {
  position: absolute;
  content: "";
  z-index: 3;
  width: 120px;
  height: 120px;
  bottom: -30px;
  left: -30px;
  border-radius: 50%;
  transition-duration: 500ms;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.04);
}
.solution-card:before {
  position: absolute;
  content: "";
  z-index: 5;
  width: 60px;
  height: 60px;
  transition-duration: 500ms;
  bottom: -3px;
  left: -3px;
  border-radius: 50%;
  background-repeat: no-repeat;
  @apply bg-white opacity-20;
}

.solution-icon {
  font-size: 70px;
  position: absolute;
  font-weight: lighter;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  opacity: 30;
  color: rgba(255, 255, 255, 0.93);
}

.solution-card .title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bolder;
}
.solution-card .description {
  font-size: 16px;
  font-weight: normal;
}
.solution-card:hover:after {
  transform: scale(3);
  transition-duration: 500ms;
}
.solution-card:hover:before {
  transform: scale(3);
  transition-duration: 500ms;
}
.small-card {
  transition-duration: 200ms;
  @apply flex flex-col items-center justify-center px-4 py-6 bg-white rounded-xl;
}

#login,
#register {
  /*    background-image: url("./images/login_bg.png");*/
  @apply bg-gray-100;
}
.cameraModal {
  z-index: 70 !important;
  @apply fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-white;
}

.device {
  aspect-ratio: 9/16;
  z-index: 70 !important;
  @apply fixed inset-0 w-full h-screen mx-auto overflow-hidden;
}

.bottom-controller {
  @apply w-full absolute z-50  flex justify-center items-center py-3 bottom-[1.875rem];
}

.shut-btn {
  @apply bg-primary w-[80px] h-[80px] md:w-[3.125rem] md:h-[3.125rem] rounded-full;
}

.shut-btn:disabled {
  @apply opacity-50;
}
video.camera {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
  object-fit: contain;
  /*
    @apply  h-screen w-full md:h-auto*/
}

.top-controller {
  @apply absolute top-0 z-50 flex items-center justify-start justify-between w-full py-6 bg-black px-7;
}
.top-controller-light {
  @apply absolute top-0 z-50 flex items-center justify-start justify-between w-full py-6 bg-white px-7;
}
.timer-btn {
  @apply z-50 flex items-center justify-center text-lg rounded-full;
}
.btn-file {
  @apply bg-gray-800 flex text-xl justify-center w-[3.125rem] h-[3.125rem] md:w-[2.2rem] md:h-[2.2rem] items-center rounded-full;
}
.switch-btn {
  @apply bg-gray-800 flex text-lg justify-center w-[3.125rem] h-[3.125rem] md:w-[2.2rem] md:h-[2.2rem] items-center rounded-full;
}

.timer {
  transform: translateX(-50%) translateY(-50%);
  @apply z-30 absolute flex top-[50%] justify-center items-center   w-[9.375rem]  h-[9.375rem] text-white left-[50%];
}
.timer span {
  @apply text-xl drop-shadow-lg;
}
.layout-height {
  min-height: calc(100vh - 270px);
}
.active-link {
  position: relative;
  @apply text-primary;
}
.active-link:before {
  content: "";
  position: absolute;
  right: 0px;
  width: 5px;
  border-radius: 2px;
  height: 100%;
  top: 0;
  @apply bg-primary;
}

.link {
  @apply py-4 w-full flex justify-start items-center  border-b px-[15px];
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
}
.drawer-ltr {
}
.drawer-rtl {
  font-family: "YekanBakh", serif !important;
}
.light-solution {
  @apply bg-white text-primary;
}
.solution-card.light-solution {
  @apply !text-primary;
}

.light-solution:before {
  background-color: rgb(242, 249, 255) !important;
}
.light-solution:after {
  background-color: rgb(189, 210, 255) !important;
}
.light-solution .solution-icon {
  @apply !text-primary;
}
.iranYekan {
  font-family: "iranYekan", serif !important;
}
.yekanBakh {
  font-family: "YekanBakh", serif !important;
}
.profile-gender label {
  @apply !w-6/12  md:w-auto;
}
/*.ant-btn.ant-btn-lg{
    font-size: 18px!important;
    height:50px!important;
}*/
input.ant-input {
  font-size: 16px !important;
  padding: 5px !important;
}
.ant-input.ant-input-lg {
}
.ant-picker-large input {
  font-size: 16px !important;
  padding: 5px !important;
}
.ant-input-number-affix-wrapper-lg {
  font-size: 16px !important;
  padding: 5px !important;
}
.ant-drawer-body {
  @apply flex flex-col justify-between;
}
#avatar-wrapper .ant-upload-wrapper {
  display: flex;
  justify-content: center;
}
#friends-card {
  background-image: url("./images/friends.jpg");
}
#visual-clothes-card {
  background-image: url("./images/visual-clothe-serach.png");
}
#body-measurement-card {
  background-image: url("./images/body-measurement.jpg");
}
.service-card {
  position: relative;
  display: flex;
  justify-content: center;
  aspect-ratio: 4/2;
  align-items: center;
  background-size: cover;

  @apply overflow-hidden rounded-2xl;
}
.service-card:before {
  transition-duration: 200ms;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @apply bg-gray-800 bg-opacity-80 rounded-2xl;
}

.service-card:before:hover {
  transition-duration: 200ms;
  @apply bg-gray-800 bg-opacity-80 backdrop-blur-sm rounded-2xl;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background: inherit;

  background-image: none;
  @apply text-primary;
}
.ant-btn-primary {
  @apply text-white bg-primary;
}
.btn-primary-outline {
  @apply !bg-transparent !text-primary border-primary !border;
}

.btn-primary {
  @apply !bg-primary !text-white !border-0;
}

.btn-white {
  @apply !bg-white !text-primary !border-0;
}
.btn-white-outline {
  @apply text-white bg-transparent border-white;
}
.btn-dark {
  @apply !bg-black !text-white !border-0;
}
.btn-success {
  @apply !bg-green-500 !text-white !border-0;
}
.service-image {
  max-height: 300px;
  max-width: 100%;
  height: auto;
  width: auto;
}
#guest {
  background-image: url("./images/login_bg.png");
  @apply bg-primary;
  background-size: cover;
  background-position: center center;
}
.bottom-navigation {
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1);
  z-index: 45 !important;
}
.icon g {
  @apply fill-gray-800;
}
.active-icon .icon g,
.active-icon .icon path {
  @apply fill-primary;
}
.default-icon {
  @apply px-3 py-2 opacity-50;
}

.active-icon {
  @apply relative px-3 py-2 rounded-full bg-opacity-20 text-primary;
}

.active-icon:before {
  position: absolute;
  width: 100%;
  height: 3px;
  animation-delay: 100ms;
  top: -10px;
  @apply bg-primary;
  content: "";
  z-index: 0;
}
.active-icon .measure-icon path {
  @apply !fill-primary;
}
.ant-btn.ant-btn-lg {
  padding: 12px !important;
  border-radius: 14px !important;
  height: auto !important;
  font-size: 16px !important;
  font-width: bold;
}
.ant-form-item-control-input iframe {
  width: 100% !important;
  margin: 8px 0 !important;
}
.ant-form-item-control-input iframe button {
  width: 100% !important;
}
.ant-form-item-control-input iframe .container {
  padding: 0 !important;
}
.ant-form-item-control-input #container {
  width: 100% !important;
  padding: 0 !important;
}
p:first-letter {
  text-transform: uppercase !important;
}
h1:first-letter {
  text-transform: uppercase !important;
}
h2:first-letter {
  text-transform: uppercase !important;
}
h3:first-letter {
  text-transform: uppercase !important;
}
h4:first-letter {
  text-transform: uppercase !important;
}
h5:first-letter {
  text-transform: uppercase !important;
}
h6:first-letter {
  text-transform: uppercase !important;
}
div:first-letter {
  text-transform: uppercase !important;
}
label:first-letter {
  text-transform: capitalize !important;
}
span:first-letter {
  text-transform: uppercase !important;
}
button:first-letter {
  text-transform: uppercase !important;
}
body {
  zoom: 1;
}
html body[dir="rtl"] {
  font-family: "YekanBakh", serif !important;
}
.body-rtl {
  font-family: "YekanBakh", serif !important;
}
.body-rtl h1 {
  font-weight: 800 !important;
}
.body-rtl h2 {
  font-weight: 800 !important;
}
.body-rtl h3 {
  font-weight: 800 !important;
}
.body-rtl h4 {
  font-weight: 800 !important;
}
.body-rtl strong {
  font-weight: 800 !important;
}
.body-rtl .custom-css span {
  font-family: "YekanBakh", serif !important;
}
.rtl-grid {
  direction: rtl !important;
}
.ltr-grid {
  direction: ltr !important;
}
.table-rtl {
}
.body-rtl {
  font-family: "iranYekan", serif !important;
  direction: rtl !important;
}

.human-guideline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevents interactions with the guideline */
  z-index: 1; /* Ensure the guideline is above the webcam feed */
  background-color: blue;

  border: 2px solid red;
}

/* Head */
.head {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 45%;
}

/* Body */
.body {
  position: absolute;
  top: 30%;
  left: 45%;
  width: 50px;
  height: 120px;
}

/* Arms */
.left-arm,
.right-arm {
  width: 10px;
  height: 60px;
  background-color: white;
  position: absolute;
  top: 20px;
}

.left-arm {
  left: -20px;
  transform: rotate(-45deg);
}

.right-arm {
  right: -20px;
  transform: rotate(45deg);
}

/* Legs */
.left-leg,
.right-leg {
  width: 10px;
  height: 60px;
  background-color: white;
  position: absolute;
  bottom: 0;
}

.left-leg {
  left: -10px;
  transform: rotate(45deg);
}

.right-leg {
  right: -10px;
  transform: rotate(45deg);
}
.safe {
  height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}
.bg-p {
  background-color: #f5f5f5;
}
.degree-wrapper {
  position: absolute !important;
  width: 100%;
  z-index: 300;
}
.z-degree {
  z-index: 400;
}

@supports (-webkit-touch-callout: none) {
  .safe {
    height: -webkit-fill-available;
  }
}
.camera-degree-controller {
  height: 200px;
  padding: 3px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 3px;
}
.degree-switcher {
  z-index: 50;

  left: 50%;
  transform: translateX(-50%);
  @apply absolute z-40 flex items-center justify-center w-6 h-6 bg-red-500 rounded-full;
}
.measurement-capture-wrapper {
  z-index: 100;
  @apply fixed -translate-x-1/2 bottom-5 left-1/2;
}
.measurement-capture-button {
  @apply bg-primary w-[3.75rem] text-white h-[3.75rem] rounded-full;
}
.disabled-deg-title {
  z-index: 180;
  padding-top: 30px;
  @apply fixed top-0 flex items-center justify-center w-full text-xl text-white uppercase;
}
.stand-image {
  height: 70dvh;
}
.result-card {
  border-radius: 12px;
  padding: 16px 0;
  width: 130px;
  margin: 0 16px;
  height: 180px;
  @apply relative bg-white;
}
.result-card .icon-wrapper {
  width: 45px;
  height: 45px;
  @apply flex items-center justify-center bg-gray-100 rounded-full;
}
.result-card .icon-wrapper img {
  height: 25px;
  width: auto;
}

.result-card .line {
  margin-top: 8px;
}
.result-card.active .icon-wrapper {
  @apply bg-white;
}
.result-card.active {
  @apply bg-primary;
}
.result-card .result-value {
  font-size: 17px;
  position: absolute;
  left: 16px;
  bottom: 16px;
  font-weight: 700;
  @apply text-primary;
}
.result-card.active .result-value {
  @apply text-white;
}
.result-card .result-title {
  font-size: 13px;
  margin-left: 4px;
  font-width: bold;
  @apply text-gray-400;
}

.result-card.active .result-title {
  font-size: 13px;
  margin-left: 4px;
  font-width: bold;
  @apply text-white;
}
.result-image {
  height: 400px;
  position: relative;
}
.result-image-wrapper {
  position: relative;
  height: 400px;
  z-index: 30;
}
.result-image-wrapper svg {
  height: 400px;
}

@media (max-height: 667px) {
  .result-image-wrapper {
    position: relative;
    height: 280px;
    z-index: 50;
  }
  .result-image-wrapper svg {
    height: 280px;
  }
}
@keyframes image-show {
  from {
    z-index: 50;
    opacity: 0;
    transform: translateX(80px);
  }
  to {
    z-index: 50;
    opacity: 1;
    transform: translateX(0px);
  }
}

.result-animation {
  opacity: 1;
  animation-duration: 1s;
  animation-name: image-show;
}
.result-leg-animation {
  opacity: 1;
  animation-duration: 2s;
  animation-name: image-show;
}

.slider-wrapper {
}
svg.leg-body {
  opacity: 1;
  z-index: 50;
  animation-duration: 1s;
  animation-name: image-show;
}

svg.leg-body .fade-right {
  opacity: 1;
  animation-duration: 1s;
  animation-name: image-show;
}
.gradient-fade-top {
  background: linear-gradient(180deg, rgba(243, 244, 246), rgba(243, 244, 246, 0));
}
.gradient-fade-bottom {
  background: linear-gradient(0deg, rgba(243, 244, 246), rgba(243, 244, 246, 0));
}
.z-1000 {
  z-index: 1000;
}

/* sdk-welcome-modal */
.sdk-welcome-modal .ant-modal-content {
  border-radius: 8px;
  padding: 24px;
}

.sdk-welcome-modal .ant-modal-header {
  border-bottom: none;
  padding: 0;
}

.sdk-welcome-modal .ant-modal-body {
  padding: 16px 0 0;
}

.sdk-welcome-modal .ant-modal-footer {
  border-top: none;
  padding: 0;
}

.sdk-welcome-modal .ant-divider {
  margin: 16px 0;
}

.sdk-welcome-modal .btn-primary {
  background-color: #474bcf;
  border-color: #474bcf;
}

.sdk-welcome-modal .btn-primary:hover,
.sdk-welcome-modal .btn-primary:focus {
  background-color: #5c60d6;
  border-color: #5c60d6;
}

@media (max-width: 640px) {
  .sdk-welcome-modal .ant-modal-content {
    padding: 16px;
  }

  .sdk-welcome-modal .ant-modal-body {
    padding: 8px 0 0;
  }
}

.sdk-login-modal .ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.sdk-login-modal .ant-form-item-label > label {
  font-weight: 600;
  color: #333;
}

.sdk-login-modal .ant-input-affix-wrapper {
  border-radius: 8px;
}

.sdk-login-modal .ant-input-affix-wrapper:hover,
.sdk-login-modal .ant-input-affix-wrapper:focus {
  border-color: #474bcf;
  box-shadow: 0 0 0 2px rgba(71, 75, 207, 0.2);
}

.sdk-login-modal .ant-btn-primary {
  background-color: #474bcf;
  border-color: #474bcf;
}

.sdk-login-modal .ant-btn-primary:hover,
.sdk-login-modal .ant-btn-primary:focus {
  background-color: #5c60d3;
  border-color: #5c60d3;
}

.modal-wrapper {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
  }
  .ant-modal-body {
    padding: 10px;
  }
  .ant-modal-footer {
    border-top: 1px solid #ddd;
  }
}
